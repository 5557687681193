import { Fragment, useEffect, useState } from "react";
import PostDetailCard from "../../shared-components/PostDetailCard";
// import RecruitmentHero from "../../components/recruitments/RecruitmentHero";
import RecruitmentHero from "../../components/recruitment/RecruitmentHero";
import recruitmentService from "../../service/app";
import { useParams } from "react-router-dom";

export default function RecruitmentDetailPage({ error }) {
  const [item, setItem] = useState({});
  const { id } = useParams();

  useEffect(() => {
    recruitmentService
      .getData("recruitments", id)
      .then((result) => setItem(result.data));
  }, []);

  return (
    <Fragment>
      <RecruitmentHero item={item} error={error} />
      <PostDetailCard item={item} error={error} />
    </Fragment>
  );
}
