import { Fragment } from "react";
import BlogsHero from "../../components/blogs/BlogsHero";
import OurBlogs from "../../components/blogs/OurBlogs";

export default function BlogsPage({ items, error }) {
  return (
    <Fragment>
      <BlogsHero />
      <OurBlogs items={items} error={error} />
    </Fragment>
  );
}
