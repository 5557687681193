import axios from "axios";

const baseUrl = `https://rcs-blog-api.rcs-mm.com/api/v1`;

export const getAllData = async (path) => {
  const response = await axios.get(`${baseUrl}/${path}`);
  return response.data;
};

export const getData = async (path, id) => {
  const response = await axios.get(`${baseUrl}/${path}/${id}`);
  return response.data;
};

export const getVisitors = async (path) => {
  const response = await axios.get(`${baseUrl}/${path}`);
  return response.data;
};

export const increaseVisitors = async (path) => {
  const response = await axios.get(`${baseUrl}/${path}`);
  return response.data;
};

export default {
  getAllData,
  getData,
  getVisitors,
  increaseVisitors,
};
