import { Fragment, useState, useEffect } from "react";
import NewHero from "../../components/new/NewHero";
import PostDetailCard from "../../shared-components/PostDetailCard";
import newandeventService from "../../service/app";
import { useParams } from "react-router-dom";

export default function NewsDetailPage({ error }) {
  const [item, setItem] = useState({});
  const { id } = useParams();

  useEffect(() => {
    newandeventService
      .getData("newsandevents", id)
      .then((result) => setItem(result.data));
  }, []);

  return (
    <Fragment>
      <NewHero item={item} error={error} />
      <PostDetailCard item={item} error={error} />
    </Fragment>
  );
}
