import HeroText from "../../shared-components/HeroText";

const RecruitmentHero = ({ item, error }) => {
  return error ? (
    <div className="erroralert">
      <h1>No Post Found or Something was wrong. Please, try later!</h1>
    </div>
  ) : (
    <HeroText title={item.jobtitle} url={item.image} />
  );
};

export default RecruitmentHero;
