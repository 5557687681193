import styles from "./WebAppServiceDetail.module.css";
import CheckoutBtn from "../../shared-components/CheckoutBtn";
// import data from "../../locales/en-US/webAppServiceDetail.json";
import { useTranslation } from "react-i18next";

function WebAppServiceDetail() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>Web Application</h1>
      <div className={styles.detailcard}>
        <div className={styles.textcontainer}>
          <p>
            <b>{t("webAppServiceDetail.web-app-hightlight-1")}</b>
            {t("webAppServiceDetail.web-app-description-1")}
          </p>
          <p>
            <b>{t("webAppServiceDetail.web-app-hightlight-2")}</b>
            {t("webAppServiceDetail.web-app-description-2")}
          </p>
          <p>
            <b>{t("webAppServiceDetail.web-app-hightlight-3")}</b>
            {t("webAppServiceDetail.web-app-description-3")}
          </p>

          <CheckoutBtn path="Web App Service" />
        </div>
        <img
          src="/images/services/web-application/web-application-1.jpg"
          alt="Web Application"
          width={400}
          height={300}
          className={styles.image}
        />
      </div>
    </div>
  );
}

export default WebAppServiceDetail;
