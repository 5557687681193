import React, { useRef, useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import styles from "./OurPhotosNew.module.css";
import { Image } from "semantic-ui-react";

const OurPhotosNew = () => {
  let resetTimeout;
  const carouselRef = useRef(null);

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    function updateSize() {
      setTotalPages(window.innerWidth >= 1119 ? 5 : 6);
    }
    window.addEventListener("resize", updateSize);

    updateSize();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const breakPoints = [
    { width: 450, itemsToShow: 1 },
    { width: 950, itemsToShow: 2 },
  ];

  return (
    <div className={styles.carousel_wrapper}>
      <div className={styles.container}>
        <Carousel
          breakPoints={breakPoints}
          ref={carouselRef}
          enableAutoPlay
          autoPlaySpeed={1500} // same time
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            if (index + 1 === totalPages) {
              resetTimeout = setTimeout(() => {
                carouselRef?.current?.goTo(0);
              }, 1500); // same time
            }
          }}
        >
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/Heaven.jpg"
              alt="Gallery 1"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                HEAVEN PRICE
              </strong>
              <br />
              <span>Paper Packing Factory</span>
            </div>
          </div>
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/M9 copy 2.jpg"
              alt="Gallery 2"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                MAHAR NINE
              </strong>
              <br />
              <span>Logistics Company Limited</span>
            </div>
          </div>
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/My mobile (1).jpg"
              alt="Gallery 3"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                My Mobile
              </strong>
              <br />
              <span> Mobile & IT</span>
            </div>
          </div>
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/top power 7.jpg"
              alt="Gallery 4"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                Top Power Group
              </strong>
              <br />
              <span>Top Power</span>
            </div>
          </div>
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/NT copy.jpg"
              alt="Gallery 5"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                NT Branded Collection
              </strong>
              <br />
              <span>Ecommerce Website</span>
            </div>
          </div>
          <div className={styles.imagecontainer}>
            <Image
              src="/images/client-products/M9 copy 2.jpg"
              alt="Gallery 2"
              height={600}
              width={600}
            />
            <div className={styles.imagetitle}>
              <strong>
                <br />
                MAHAR NINE
              </strong>
              <br />
              <span>Logistics Company Limited</span>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default OurPhotosNew;
