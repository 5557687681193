import { Fragment } from "react";
import NewsHero from "../../components/news/NewsHero";
import OurNews from "../../components/news/OurNews";

export default function NewsPage({ items, error }) {
  return (
    <Fragment>
      <NewsHero />
      <OurNews items={items} error={error} />
    </Fragment>
  );
}
