// import Image from "next/image";
import styles from "./PostDetailCard.module.css";
import urlBuilder from "../lib/imageUrl";
import ReactMarkdown from "react-markdown";
import { Interweave } from "interweave";

// chang date format
function formatDate(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[0], // get only two digits
    month = datePart[1],
    day = datePart[2];

  return day + "/" + month + "/" + year;
}

function PostDetailCard({ item, error }) {
  console.log(item.deadline);
  if (item.jobdescription) {
    return error ? null : (
      <div className={styles.container}>
        <div className={styles.detailcard}>
          <div className={styles.imagecontainer}>
            <img src={item.image} alt={item.title} width={307} height={307} />
          </div>
          <div className={[styles.textcontainer, "paragraphfont"].join(" ")}>
            <div>
              <p className={styles.jobinfo}>
                <strong>Job Title: </strong>
                {item.jobtitle}
              </p>

              <p className={styles.jobinfo}>
                <strong>Job Position: </strong>
                {item.positions} ({item.jobtype})
              </p>

              <p className={styles.jobinfo}>
                <strong>Job DeadLine: </strong>
                {item.deadline !== "0000-00-00" && (
                  <>{formatDate(item?.deadline.split("-").join("/"))}</>
                )}
              </p>

              <div>
                <p>
                  <strong>JobDescription:</strong>{" "}
                </p>
                <Interweave content={item.jobdescription} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // change <p>some</p> to some
  if (item?.description) {
    return error ? null : (
      <div className={styles.container}>
        <div className={styles.detailcard}>
          <div className={styles.imagecontainer}>
            <img src={item.image} alt={item.title} width={307} height={307} />
          </div>
          <div className={[styles.textcontainer, "paragraphfont"].join(" ")}>
            {item.subtitle && <h3>{item.subtitle}</h3>}
            <Interweave content={item.description} />
          </div>
        </div>
      </div>
    );
  }
}

export default PostDetailCard;
