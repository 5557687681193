import styles from "./OurServices.module.css";
import data from "../../locales/en-US/services.json";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function OurServices() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>Our Services</h1>
      <div className={styles.cardcontainer}>
        {/* Website Service */}
        <div className={styles.card}>
          <div className={styles.imagecontainer}>
            <img
              src="/images/services/website/website-service.jpg"
              alt="Website Service"
              className={styles.image}
            />
          </div>
          <h4>{data.website}</h4>
          <p>{t("services.website-description")}</p>
          <ul>
            <li>{t("services.website-description-list-1")}</li>
            <li>{t("services.website-description-list-2")}</li>
            <li>{t("services.website-description-list-3")}</li>
            <li>{t("services.website-description-list-4")}</li>
          </ul>
          <Link to="/services/website">
            <span className={styles.btn}>Read More</span>
          </Link>
        </div>

        {/* Web App */}
        <div className={styles.card}>
          <div className={styles.imagecontainer}>
            <img
              src="/images/services/web-application/web-application-1.jpg"
              alt="Website Application"
              className={styles.image}
            />
          </div>
          <h4>{data.webapp}</h4>
          <p>{t("services.webapp-description")}</p>
          <ul>
            <li>{t("services.webapp-description-list-1")}</li>
            <li>{t("services.webapp-description-list-2")}</li>
            <li>{t("services.webapp-description-list-3")}</li>
          </ul>
          <Link to="/services/web-application">
            <span className={styles.btn}>Read More</span>
          </Link>
        </div>

        {/* Android Application */}
        <div className={styles.card}>
          <div className={styles.imagecontainer}>
            <img
              src="/images/services/android-application/android-application.jpg"
              alt="Website Application"
              className={styles.image}
            />
          </div>
          <h4>{data.android}</h4>
          <ul>
            <li>{t("services.android-description-list-1")}</li>
            <li>{t("services.android-description-list-2")}</li>
            <li>{t("services.android-description-list-3")}</li>
          </ul>
          <Link to="/services/android-application">
            <span className={styles.btn}>Read More</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
