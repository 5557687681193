import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BlogHero from "../../components/blog/BlogHero";
import PostDetailCard from "../../shared-components/PostDetailCard";
import blogService from "../../service/app";

export default function BlogDetailPage({ error }) {
  const { id } = useParams();
  const [item, setItem] = useState({});

  useEffect(() => {
    blogService.getData("blogs", id).then((result) => setItem(result.data));
  }, []);

  return (
    <Fragment>
      <BlogHero item={item} error={error} />
      <PostDetailCard item={item} error={error} />
    </Fragment>
  );
}
