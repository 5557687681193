const productsMM = [
  {
    id: "1",
    name: "POS Offline Version",
    about:
      "လက်လီလက်ကား အရောင်းဆိုင်များ၏ အရောင်းအဝယ်စာရင်းများနှင့်ကုန်ပစ္စည်းစာရင်းများကို တိကျမှန်ကန်မြန်ဆန်အောင် ထိန်းချုပ်ပေးနိုင်သော Point Of Sales System တစ်ခုဖြစ်ပါတယ်။",
    featuresIncluded: [
      "အရောင်းတစ်ခုစာ၏ မှတ်တမ်းကို စစ်ဆေးနိုင်ခြင်း",
      "ပစ္စည်းအလိုက်အရောင်းမှတ်တမ်းကို စစ်ဆေးနိုင်ခြင်း",
      "နေ့အလိုက်၊ လအလိုက် အရောင်းစာရင်းချုပ်ကို စစ်ဆေးနိုင်ခြင်း",
      "ရက်အလိုက်အရောင်းစာရင်းချုပ်ကို စစ်ဆေးနိုင်ခြင်း",
      "လက်ကျန်နည်းနေသော ပစ္စည်းများကို စစ်ဆေးနိုင်ခြင်း",
      "ရောင်းရသော ပစ္စည်းများနှင့် အရေအတွက်ကို စစ်ဆေးနိုင်ခြင်း",
      "ပစ္စည်းပို့ စာရင်းကို စစ်ဆေးနိုင်ခြင်း",
      "ပစ္စည်းအဝင်စာရင်းကို စစ်ဆေးနိုင်ခြင်း",
      "အဝယ်များသော ပစ္စည်းများကို စစ်ဆေးနိုင်ခြင်း",
      "ဘောင်ချာကို ပရင်တာဖြင့်ထုတ်နိုင်ခြင်း",
    ],
    servicesIncluded: [
      "ကျွမ်းကျင်စွာအသုံးပြုနိုင်သည်အထိ Software Training ပေးခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်း အခမဲ့ ဆောင်ရွက်ပေးခြင်း",
      "Lifetime service အခမဲ့ပေးခြင်း (ရုံးသို့လာရောက်ရန်လိုအပ်ပါသည်)",
      "ဆော့ဝဲလ်ကြောင့်ဖြစ်သော error များ ၂၄နာရီအတွင်း ဖြေရှင်းပေးခြင်း",
      "အခမဲ့ပို့ဆောင်ပေးခြင်း (နယ်မှမှာယူလျှင် ကားဂိတ်အထိ အခမဲ့ပို့ဆောင်ပေးမည်။)",
    ],
    featuresSoftware: [
      "ကွန်ပျူတာ/စာရင်းကိုင် အခြေခံမရှိသူများပါ အဆင်ပြေစွာ အသုံးပြုနိုင်ခြင်း",
      "ရှင်းလင်းသောဒီဇိုင်းပုံစံဖြင့်ရေးဆွဲထားခြင်း",
      "မိမိစိတ်ကြိုက် ဒီဇိုင်းကာလာကို ရွေးချယ်နိုင်ခြင်း",
      "တစ်ခါဝယ်ယူထားရုံဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်းပုံစံ ၂မျိုးကို မိမိစိတ်ကြိုက်ရွေးချယ်နိုင်ခြင်း",
      "ငွေပေးချေမှု Cash on Delivery၊ Kpay သို့မဟုတ် CB Pay ဖြင့်ပေးချေနိုင်ခြင်း (% ပေးရန်မလိုပါ)",
    ],
    requiredEquipments: ["Tablet", "Receipt Printer", "Receipt Paper"],
    benefits: [
      "ကြီးထွားလာနေသော မိမိလုပ်ငန်းအတွက် နည်းပညာအသုံးပြုခြင်းက ဝန်ထမ်းအင်အား၊ အချိန်ကုန်ကျ သက်သာ၍ လုပ်ငန်း ပိုမိုတွင်ကျယ်စေခြင်း",
      "သုည မှားယွင်းမှုအတွက် ဆုံးရှုံးမှုများ မရှိနိုင်တော့ခြင်း",
      "စာရင်းဇယားများကို ပိုမို၍ စနစ်တကျ အလွယ်တကူ စစ်ဆေးနိုင်ခြင်း",
      "လုပ်ငန်း impression ပိုမိုကောင်းမွန်ခြင်း",
      "တစ်ခါဝယ်ယူခြင်းဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
    ],
    note: "လူကြီးမင်းတို့မှ ထပ်မံထည့်သွင်းလိုသော လုပ်ဆောင်ချက်များနှင့် ပြုပြင်လိုသော လုပ်ဆောင်ချက်များကို အသေးစိတ်ညှိနှိုင်းဆွေးနွေးနိုင်ပါသည်။",
    price: 280000,
    featured: true,
    image: "/images/products/offline-pos.jpg",
  },

  {
    id: "2",
    name: "POS Customize Version",
    about:
      "POS software ကို မိမိစိတ်ကြိုက် လုပ်ဆောင်မှုများ ထည့်သွင်းပေးသည့် ဆော့ဝဲလ်အမျိုးအစားဖြစ်ပါတယ်။ လူကြီးမင်းတို့ စိတ်ကြိုက် လုပ်ဆောင်ချက်များကို ညှိနှိုင်းထည့်သွင်းပေးသွားမှာဖြစ်ပါတယ်။",
    featuresIncluded: [
      "နေ့အလိုက်၊ လအလိုက်ပစ္စည်းအရောင်းစာရင်းမှတ်တမ်းများ",
      "နေ့အလိုက်၊ လအလိုက်ပစ္စည်းအဝယ်စာရင်းမှတ်တမ်းများ",
      "နေ့အလိုက်၊ လအလိုက်ပစ္စည်းစာရင်းမှတ်တမ်းများ",
      "ဘောင်ချာထုတ်ခြင်း",
      "Online, Offline ဆော့ဝဲလ်ပေါ်မူတည်၍ အထက်ပါ  လုပ်ဆောင်ချက်များအပြင် အခြားသော လုပ်ဆောင်ချက်များ ပါရှိပါတယ်။",
    ],
    servicesIncluded: [
      "ကျွမ်းကျင်စွာအသုံးပြုနိုင်သည်အထိ Software Training ပေးခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်း အခမဲ့ ဆောင်ရွက်ပေးခြင်း",
      "Lifetime service အခမဲ့ပေးခြင်း (ရုံးသို့လာရောက်ရန်လိုအပ်ပါသည်)",
      "ဆော့ဝဲလ်ကြောင့်ဖြစ်သော error များ ၂၄နာရီအတွင်း ဖြေရှင်းပေးခြင်း",
      "အခမဲ့ပို့ဆောင်ပေးခြင်း (နယ်မှမှာယူလျှင် ကားဂိတ်အထိ အခမဲ့ပို့ဆောင်ပေးမည်။)",
    ],
    featuresSoftware: [
      "ကွန်ပျူတာ/စာရင်းကိုင် အခြေခံမရှိသူများပါ အဆင်ပြေစွာ အသုံးပြုနိုင်ခြင်း",
      "ရှင်းလင်းသောဒီဇိုင်းပုံစံဖြင့်ရေးဆွဲထားခြင်း",
      "မိမိစိတ်ကြိုက် ဒီဇိုင်းကာလာကို ရွေးချယ်နိုင်ခြင်း",
      "တစ်ခါဝယ်ယူထားရုံဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်းပုံစံ ၂မျိုးကို မိမိစိတ်ကြိုက်ရွေးချယ်နိုင်ခြင်း",
      "ငွေပေးချေမှု Cash on Delivery၊ Kpay သို့မဟုတ် CB Pay ဖြင့်ပေးချေနိုင်ခြင်း (% ပေးရန်မလိုပါ)",
    ],
    requiredEquipments: ["Tablet", "Receipt Printer", "Receipt Paper"],
    benefits: [
      "ကြီးထွားလာနေသော မိမိလုပ်ငန်းအတွက် နည်းပညာအသုံးပြုခြင်းက ဝန်ထမ်းအင်အား၊ အချိန်ကုန်ကျ သက်သာ၍ လုပ်ငန်း ပိုမိုတွင်ကျယ်စေခြင်း",
      "သုည မှားယွင်းမှုအတွက် ဆုံးရှုံးမှုများ မရှိနိုင်တော့ခြင်း",
      "စာရင်းဇယားများကို ပိုမို၍ စနစ်တကျ အလွယ်တကူ စစ်ဆေးနိုင်ခြင်း",
      "လုပ်ငန်း impression ပိုမိုကောင်းမွန်ခြင်း",
      "တစ်ခါဝယ်ယူခြင်းဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
    ],
    note: "လူကြီးမင်းတို့မှ ထပ်မံထည့်သွင်းလိုသော လုပ်ဆောင်ချက်များနှင့် ပြုပြင်လိုသော လုပ်ဆောင်ချက်များကို အသေးစိတ်ညှိနှိုင်းဆွေးနွေးနိုင်ပါသည်။",
    price: 280000,
    featured: true,
    image: "/images/products/customize-pos.jpg",
  },

  {
    id: "3",
    name: "POS Inventory Management Version",
    about:
      "လက်လီလက်ကား အရောင်းဆိုင်များ၏ အရောင်းအဝယ်စာရင်းများနှင့်ကုန်ပစ္စည်းစာရင်းများအသေးစိတ်ကို တိကျမှန်ကန်မြန်ဆန်အောင် ထိန်းချုပ်ပေးနိုင်သော Point Of Sales System တစ်ခုဖြစ်ပါတယ်။",
    featuresIncluded: [
      "POS Offline Version တွင်ပါဝင်သော လုပ်ဆောင်ချက်များအပြင်",
      "ပစ္စည်းစာရင်းလက်ကျန်များ အရေအတွက်အနည်းအများကို notification ဖြင့် အသိပေးခြင်း",
      "ဆိုင်ခွဲများ၏ ပစ္စည်းစာရင်းကို အချိန်နှင့်တပြေးညီ ကြည့်ရှုနိုင်ခြင်း",
      "ပစ္စည်းအပို့အယူများကို မှတ်တမ်းသွင်းထား၍ ကြည့်ရှုနိုင်ခြင်း",
      "အွန်လိုင်းချိတ်ဆက်အသုံးပြုထားခြင်းကြောင့် မိမိစက်ပျက်သွားသော်လည်း server မှ အချက်အလက်များ ပြန်လည်ရယူနိုင်ခြင်း",
      "နေ့အလိုက်၊ လအလိုက် အရောင်းစာရင်း (Sales Report) ကြည့်ရှုနိုင်ခြင်း",
      "နေ့အလိုက်၊ လအလိုက် ပစ္စည်းစာရင်းမှတ်တမ်း (Inventory Report) ကြည့်ရှုနိုင်ခြင်း",
      "နေ့အလိုက်၊ လအလိုက် အရောင်းကောင်တာမှအရောင်းစာရင်း (Cashier Report) ကြည့်ရှုနိုင်ခြင်း",
    ],
    servicesIncluded: [
      "ကျွမ်းကျင်စွာအသုံးပြုနိုင်သည်အထိ Software Training ပေးခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်း အခမဲ့ ဆောင်ရွက်ပေးခြင်း",
      "Lifetime service အခမဲ့ပေးခြင်း (ရုံးသို့လာရောက်ရန်လိုအပ်ပါသည်)",
      "ဆော့ဝဲလ်ကြောင့်ဖြစ်သော error များ ၂၄နာရီအတွင်း ဖြေရှင်းပေးခြင်း",
      "အခမဲ့ပို့ဆောင်ပေးခြင်း (နယ်မှမှာယူလျှင် ကားဂိတ်အထိ အခမဲ့ပို့ဆောင်ပေးမည်။)",
    ],
    featuresSoftware: [
      "ကွန်ပျူတာ/စာရင်းကိုင် အခြေခံမရှိသူများပါ အဆင်ပြေစွာ အသုံးပြုနိုင်ခြင်း",
      "ရှင်းလင်းသောဒီဇိုင်းပုံစံဖြင့်ရေးဆွဲထားခြင်း",
      "မိမိစိတ်ကြိုက် ဒီဇိုင်းကာလာကို ရွေးချယ်နိုင်ခြင်း",
      "တစ်ခါဝယ်ယူထားရုံဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
      "ကုန်ပစ္စည်းစာရင်းသွင်းခြင်းပုံစံ ၂မျိုးကို မိမိစိတ်ကြိုက်ရွေးချယ်နိုင်ခြင်း",
      "ငွေပေးချေမှု Cash on Delivery၊ Kpay သို့မဟုတ် CB Pay ဖြင့်ပေးချေနိုင်ခြင်း (% ပေးရန်မလိုပါ)",
    ],
    requiredEquipments: ["Tablet", "Receipt Printer", "Receipt Paper"],
    benefits: [
      "ကြီးထွားလာနေသော မိမိလုပ်ငန်းအတွက် နည်းပညာအသုံးပြုခြင်းက ဝန်ထမ်းအင်အား၊ အချိန်ကုန်ကျ သက်သာ၍ လုပ်ငန်း ပိုမိုတွင်ကျယ်စေခြင်း",
      "သုည မှားယွင်းမှုအတွက် ဆုံးရှုံးမှုများ မရှိနိုင်တော့ခြင်း",
      "စာရင်းဇယားများကို ပိုမို၍ စနစ်တကျ အလွယ်တကူ စစ်ဆေးနိုင်ခြင်း",
      "အချက်အလက်များ Back-up လုပ်ထားနိုင်၍ အချိန်မရွေး၊ နေရာမရွေးစစ်ဆေးနိုင်ခြင်း",
      "လုပ်ငန်း impression ပိုမိုကောင်းမွန်ခြင်း",
      "တစ်ခါဝယ်ယူခြင်းဖြင့် တစ်သက်တာ အသုံးပြုနိုင်ခြင်း",
    ],
    note: "လူကြီးမင်းတို့မှ ထပ်မံထည့်သွင်းလိုသော လုပ်ဆောင်ချက်များနှင့် ပြုပြင်လိုသော လုပ်ဆောင်ချက်များကို အသေးစိတ်ညှိနှိုင်းဆွေးနွေးနိုင်ပါသည်။",
    price: 380000,
    featured: true,
    image: "/images/products/inventory-pos.jpg",
  },
];

export function getProductsMM() {
  return productsMM;
}

export function getProductsByPriceMM(less, lot) {
  return productsMM.filter(
    (productMM) => productMM.price >= less && productMM.price < lot
  );
}

export function getDetailProductMM(id) {
  return productsMM.find((productMM) => productMM.id === id);
}

export function getFeaturedProductsMM() {
  return productsMM.filter((productMM) => productMM.featured);
}
