import { Fragment } from "react";
import OurRecruitment from "../../components/recruitments/OurRecruitment";
import RecruitmentHero from "../../components/recruitments/RecruitmentHero";

export default function RecruitmentPage({ items, error }) {
  return (
    <Fragment>
      <RecruitmentHero />
      <OurRecruitment items={items} error={error} />
    </Fragment>
  );
}
