import React, { Fragment } from "react";
import SoftwaresWeDevelop from "../shared-components/SoftwaresWeDevelop";
import TechnologiesWeUse from "../shared-components/TechnologiesWeUse";
import OurClients from "../shared-components/OurClients";
// import OurPhotos from "../shared-components/OurPhotos";
import OurPhotosNew from "../shared-components/OurPhotosNew";
function Information() {
  return (
    <Fragment>
      <SoftwaresWeDevelop />
      {/* <ClientSuccess items={items}/> */}
      <TechnologiesWeUse />
      <OurClients />
      {/* <OurPhotos /> */}
      <OurPhotosNew />
    </Fragment>
  );
}

export default Information;
