import Button from "./Button";
import styles from "./CheckProducts.module.css";
// import data from "../../locales/en-US/home.json";
import { useTranslation } from "react-i18next";
// import useTranslation from "next-translate/useTranslation";

function CheckProduct() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img
          src="/images/home/check-products.png"
          alt="Check Products"
          // width={766}
          // height={496}
          className={styles.productimage}
        />
      </div>

      <h2>
        {t("home.product_title_1")}
        {/* {data.product_title_1} */}
        <br />
        {t("home.product_title_2")}
        {/* {data.product_title_2} */}
        <br />
        {t("home.product_title_3")}
        {/* {data.product_title_3} */}
      </h2>
      <p className="paragraphfont">{t("home.product_subtitle")}</p>
      {/* <p className="paragraphfont">{data.product_subtitle}</p> */}
      <Button path="/products" label="Check Products" />
    </div>
  );
}

export default CheckProduct;
