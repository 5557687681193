import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const options = [
  <>
    <img src="/images/home/lang-eng.jpg" alt="English" width={30} height={20} />
    <span>English</span>
  </>,
  <>
    <img
      src="/images/home/lang-myan.png"
      alt="Myanmar"
      width={30}
      height={20}
    />
    <span>Myanmar</span>
  </>,
];

const lngs = {
  "en-US": { nativeName: "English", image: "/images/home/lang-eng.jpg" },
  mm: { nativeName: "Myanmar", image: "/images/home/lang-myan.png" },
};

function Footer() {
  const { t, i18n } = useTranslation();
  const [counter, setCounert] = useState(0);
  // const router = useLocation();

  // const [isActive, setIsActive] = useState(false);
  // const [selected, setSelected] = useState();
  // const [locale, setLocale] = useState("en");

  // useEffect(() => {
  //   const index = router.locales.indexOf(router.locale);
  //   setSelected(options[index]);
  // }, [router.locale, router.locales]);

  // const handleIsActive = () => setIsActive(!isActive);

  return (
    <div className={styles.container}>
      <div className={styles.contactcontainer}>
        <div className={styles.addresscontainer}>
          <h4>RCS</h4>
          <p>
            〒 05011, No.24, 2F, 87D St, Between 22 x 23 Sts, Aung Myay Thar Zan
            Tsp, Mandalay，Myanmar
          </p>
        </div>
        <div className={styles.socialcontainer}>
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} size="lg" /> +959 799501600
          </p>
          <p>
            {/* <Link href="https://mail.google.com"> */}
            <a target="_blank">
              <FontAwesomeIcon icon={faEnvelope} size="lg" /> office@rcs-mm.com
            </a>
            {/* </Link> */}
          </p>
          <p>
            {/* <Link href="https://www.facebook.com/RealCodeSolutions"> */}
            <a
              target="_blank"
              href="https://www.facebook.com/RealCodeSolutions"
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
            </a>
            {/* </Link> */}
          </p>
        </div>
      </div>
      <div className={styles.aboutcontainer}>
        <div className={styles.support}>
          <h4>Support</h4>
          <p>
            {/* <Link href="/help"> */}
            <Link to="/help">Help</Link>
            {/* </Link> */}
          </p>
          <p>
            {/* <Link href="/contact-us"> */}
            <Link to="/contact-us">Contact Us</Link>
            {/* </Link> */}
          </p>
        </div>
        <div className={styles.team}>
          <h4>Team</h4>
          <p>
            {/* <Link href="/about"> */}
            <Link to="/about">Members</Link>
            {/* </Link> */}
          </p>
        </div>
        <div className={styles.terms}>
          <h4>Terms & Policies</h4>
          <p>
            {/* <Link href="/terms-and-conditions"> */}
            <Link to="/terms-and-conditions">Terms of Uses</Link>
            {/* </Link> */}
          </p>
          <p>
            {/* <Link href="/privacy-policy"> */}
            <Link to="/privacy-policy">Privacy Policy</Link>
            {/* </Link> */}
          </p>
        </div>
        <div>
          {Object.keys(lngs).map((lng) => (
            <button
              className={styles.lang_button}
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => {
                i18n.changeLanguage(lng);
              }}
            >
              <img
                src={lngs[lng].image}
                alt="flatImage"
                height="10"
                width="15"
                style={{ marginRight: "5px" }}
              />
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
      </div>
      <p className={styles.copyright}>
        &copy; 2022 Real Code Solution. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
