import about from "./about.json";
import androidAppServiceDetail from "./androidAppServiceDetail.json";
import checkout from "./checkout.json";
import home from "./home.json";
import productDetail from "./productDetail.json";
import services from "./services.json";
import webAppServiceDetail from "./webAppServiceDetail.json";
import webServiceDetail from "./webServiceDetail.json";

export const en = {
  about,
  androidAppServiceDetail,
  checkout,
  home,
  productDetail,
  services,
  webAppServiceDetail,
  webServiceDetail,
};
