import { Fragment } from "react";
import HeroText from "../shared-components/HeroText";
import { useTranslation } from "react-i18next";
import styles from "../components/about/Speech.module.css";

export default function MaintenancePage() {
    const { t } = useTranslation();

    return (
        <Fragment>
        <HeroText
            title="Maintenance"
            url="/images/about/about-hero.jpg"
        />
        <div className={styles.container}>
            <div className={styles.card}>
            <div className={[styles.textcontainer, "paragraphfont"].join(" ")}>
                <h1>{t("about.maintenance")}</h1>
                <h5>{t("about.maintenance2")}</h5>
                </div>
            </div>
        
        </div>
        </Fragment>
    );
}
