import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import AboutPage from "./pages/about";
import BlogsPage from "./pages/blogs";
import BlogDetailPage from "./pages/blogs/BlogDetail";
import CheckoutPage from "./pages/checkout";
import ContactUsPage from "./pages/contact-us";
import HelpPage from "./pages/help";
import Home from "./pages/index";
import MaintenancePage from "./pages/maintenance";
import NewsPage from "./pages/news";
import NewsDetailPage from "./pages/news/NewsDetail";
import ProductsPage from "./pages/porducts";
import ProductDetailPage from "./pages/porducts/ProductDetail";
import PricingPage from "./pages/pricing";
import PrivacyPolicyPage from "./pages/privacy-policy";
import RecruitmentPage from "./pages/recruitments";
import RecruitmentDetailPage from "./pages/recruitments/RecruitmentDetail";
import ServicesPage from "./pages/services";
import AndroidAppServicePage from "./pages/services/android-application";
import WebAppServicePage from "./pages/services/web-application";
import WebsiteServicePage from "./pages/services/website";
import TermsAndConditionsPage from "./pages/terms-and-conditions";

import "./styles/globals.css";

function App() {
  return (
    <BrowserRouter>
      {/* <NavigationMenu /> */}
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductDetailPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/website" element={<WebsiteServicePage />} />
          <Route
            path="/services/web-application"
            element={<WebAppServicePage />}
          />
          <Route
            path="/services/android-application"
            element={<AndroidAppServicePage />}
          />
          <Route path="/pricings" element={<PricingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsDetailPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:id" element={<BlogDetailPage />} />
          <Route path="/recruitments" element={<RecruitmentPage />} />
          <Route path="/recruitments/:id" element={<RecruitmentDetailPage />} />
          <Route path="/checkout/:path" element={<CheckoutPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/after-sales-service" element={<MaintenancePage/>}/>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
