import styles from "./PostCard.module.css";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { Interweave } from "interweave";

function PostCard({
  title,
  subtitle = null,
  content,
  imageUrl,
  date,
  detailPageUrl,
}) {
  const previewContent = `${content.trim().toString().substring(0, 50)}...`;
  const previewSubtitle = `${subtitle?.trim().toString().substring(0, 50)}`;

  return (
    <div className={styles.card}>
      <img src={imageUrl} alt={title} width={300} height={280} />

      <div className={styles.textcontainer}>
        <span className={styles.title}>{title}</span>
        <span className={styles.date}>{date}</span>
      </div>

      {previewSubtitle !== "undefined" && (
        <div className={styles.subtitle}>
          <Interweave content={previewSubtitle} />
        </div>
      )}

      <div className={styles.btn_container}>
        <button className={styles.btn}>
          <Link to={detailPageUrl}>Read More</Link>
        </button>
      </div>
    </div>
  );
}

export default PostCard;
