import { Fragment, useEffect, useState } from "react";
import OurProducts from "../../components/products/OurProducts";
import ProductsHero from "../../components/products/ProductHero";
import Information from "../../components/Information";
import { getProducts } from "../../data/products";
import { getProductsMM } from "../../data/productsMM";
import { useTranslation } from "react-i18next";

export default function ProductsPage() {
  const { i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  console.log(i18n.language);

  useEffect(() => {
    setProducts(i18n.language === "en-US" ? getProducts() : getProductsMM());
  }, [i18n.language]);

  return (
    <Fragment>
      <ProductsHero />
      <OurProducts products={products} />
      <Information />
    </Fragment>
  );
}
